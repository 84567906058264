.form-control,
.form-control:hover,
.form-control:active,
.form-control::placeholder,
.form-control:focus {
  font-family: $font-family-poppins;
  letter-spacing: 0em;
  height: $select-height;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: $form-label-color !important;
}

.rmsc .dropdown-container,
.rmsc .dropdown-container:focus,
.rmsc .dropdown-container:active {
  height: $select-height;
  border: 1px solid $form-label-border !important;

}

.rmsc .dropdown-heading {
  padding: 0.375rem 0.75rem;
  height: $select-height !important;
}

.rmsc .gray {
  color: $form-label-color !important
}


// .dropdown-heading-dropdown-arrow {
//   width: 10px !important; /* Change the width of the arrow */
//   height: 6px !important; /* Change the height of the arrow */
// }

.form-control:disabled {
  background-color: $disabled-color-bg;

}

.jrf_header_elements,
.jrf_header_elements:hover,
.jrf_header_elements:active,
.jrf_header_elements:focus {
  background: $white;
  font-family: $font-family-poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: left;
}

.form-group label {
  font-family: $font-family-poppins;
  font-size: 14px;
  /*
Author : Yash Darshankar
Date : 22/01/2025
Description: According to Latest UI Disussions Making label 500 instead of 400

*/
  font-weight: 400;
  font-weight: 500;
  line-height: 21px;
  // line-height: 14.52px;
  letter-spacing: 0em;
  text-align: left;
  color: $text-color-black;
  display: flex;
  align-items: center;
}

input::placeholder {
  font-family: $font-family-poppins;
  font-size: 12px;

  font-weight: 200;
  line-height: 14.52px;
  text-align: left;
}

.form-control[type="date"]::-webkit-calendar-picker-indicator {
  background-image: url("./calender.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  border: none;
}

.card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 0px 16px;
  color: $text-color;


  font-family: $font-family-poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: left;
}

.list_body {
  background-color: $backgroundColorScreen;
  padding: 0px;
}

.searchby_container {
  display: flex;
  justify-content: right;
}

.searchby_container_input {
  width: fit-content;
  background-color: $white;
  margin: 0px 16px;
  padding-bottom: 16px;
}



.card-title h5 {
  letter-spacing: 0em;
  color: $text-color-black;
  width: 213px;
  height: 13px;
  top: 638px;
  left: 414px;

  font-family: $font-family-poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: left;
}

.submitBtn {
  background: $danger;
  color: $white;
  margin-top: 16px;
  padding: 12px 24px;
  border-radius: 5px;
  border: none;
  margin-left: 4px;
  font-family: $font-family-poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.submitBtn:hover,
.submitBtn:active,
.submitBtn:focus {
  background: #8D170A;
  color: $white;
}

.compBtn:disabled {
  background-color: grey;
  color: $white;
}

.rejectBtn {
  background: #404040;
  color: $white;

  margin-top: 16px;
  padding: 16px 32px;

  border-radius: 5px;
  border: none;
  margin-left: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1.5px 5px 0 rgba(0, 0, 0, 0.19);

  font-family: $font-family-poppins;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: center;
}


.rejectBtn:hover,
.rejectBtn:active,
.rejectBtn:focus {
  background: #404040;
  color: $white;
  opacity: 0.7;
}

.saveBtn {
  background-color: $white;
  margin-top: 16px;
  padding: 2px 8px;
  min-width: 100px;
  border-radius: 5px;
  margin-left: 4px;
  font-family: $font-family-poppins;
  height: 48px;
  border: 1px solid $danger;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: $text-color;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

}

.saveBtn:hover {
  color: $white;
  background: $danger;
}


.cancelBtn {
  background-color: $white;
  margin-top: 16px;
  padding: 8px 16px;
  height: 48px;
  min-width: 100px;
  border-radius: 5px;
  margin-left: 4px;
  font-family: $font-family-poppins;
  text-align: center;
  border: 1px solid $text-color;
  color: $text-color;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.cancelBtn:hover {
  border: 1px solid $danger;
  color: $danger;
  background-color: $white;
}

.cancelingBtn {
  all: unset;
  margin-top: 16px;
  margin-left: 4px;
  padding: 8px 16px;
  font-family: $font-family-poppins;
  text-align: center;
  color: $text-color;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-decoration: underline;
  cursor: pointer;
  display: inline;
  appearance: none;
  /* Ensures no default styles */
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  /* Ensure no background */
  border: none;
}

.cancelingBtn:hover,
.cancelingBtn:focus,
.cancelingBtn:active {
  color: $danger;
  background: transparent !important;
  /* Override any default background */
  outline: none !important;
  /* Remove any focus outline */
  border: none !important;
  box-shadow: none !important;
  /* Prevent browser button highlight */
}

// Srushti 
.auditBtns {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: 8px;
  margin-top: 4px;
}

// ----------------------------------------------------------------

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block !important;
}

.DateTimePicker .react-datepicker {
  display: flex;
}

.radioOption {
  display: flex;
  gap: 8px;
  align-items: center;
  // padding: 12px;
  padding: 4px;
  border-radius: 8px;
}

.submit_btns {
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-bottom: 32px;
  flex-wrap: wrap;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.icon-handler>i {
  position: absolute;
  top: 55%;
  right: 30px;
  transform: translateY(-50%);
  border-radius: 100px;
  padding: 10px;
}

// ----------------------------------------------------------------
.JrfReportButtonList {
  display: flex;
  justify-content: space-between;
  width: 80%;
  align-items: center;
  padding-right: 16px;
}

.JrfReportButtonList>div {
  display: flex;
  gap: 16px;
  margin: 0%;
  padding: 0%;
}

.list th {
  width: fit-content;
  font-family: $font-family-poppins;
  font-size: 14px;
  padding: 8px 4px;
  color: $text-color-black;
  cursor: pointer;
  white-space: nowrap;
  padding-left: 16px;
  font-weight: 600;
  line-height: 21px;
  text-align: center;
  ////Invoice INcoming
  // max-width: 150px;
  // /* Ensure there's a limit to wrapping */
  // word-wrap: break-word;
  // /* Allow breaking long words */
  // overflow-wrap: break-word;
  // /* Ensures wrapping works in all browsers */
  // white-space: normal;
  ///---------------------------------

}

.table {
  margin: 0%;
}

//-----Srushti Chnages
.invoiceTheadTh {
  min-width: 120px !important;
  display: table-cell;
  vertical-align: middle;
  word-break: unset;
  overflow-wrap: initial;
  background-color: burlywood;
  padding: 10px;
  white-space: wrap !important;
}


// ------------------
.mainRenderList .exclude-click {
  overflow: visible !important;
}

.table_header_icon {
  width: 24px;
  height: 23px;
  padding: 9px 7px 9px 7px;
  gap: 10px;
}

.table_header_icon>i {
  font-size: 10px;
}


.list th:first-child {
  border-radius: 12px 0px 0px 0px;
}

.list th:last-child {
  border-radius: 0px 12px 0px 0px;
}

.list {
  /*
Author : Yash Darshankar
Date : 22/01/2025
Description: According to Latest UI Disussions- Commenting this code

*/
  // border-collapse: separate;
  // border-spacing: 0px 8px;
  font-size: 0.9em;
  font-family: $font-family-poppins;
  min-width: 400px;
  border-radius: 16px;
  background-color: $white;
}

.list thead,
.list thead tr:first-child {
  border-radius: 12px 12px 0px 0px !important;
}

.list td {
  font-family: $font-family-poppins;
  white-space: nowrap;
  text-overflow: ellipsis;
  // padding: 10px 15px;
  padding: 4px 8px;
  color: $text-color;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;

}

.list_title {
  background-color: $backgroundColorScreen;
  align-items: baseline;
  display: flex;
  gap: 22px;
}

.list tbody tr:last-child {
  border: 1px solid $white;
}


// .list tbody tr {
//   height: 56px;
//   border-collapse: separate;
//   border-spacing: 10px;
//   box-shadow: 0px 4px 4px 0px #0000001f;
// }

.completed_status_tr>td:first-child {
  border-left: 4px solid $danger;
  border-collapse: separate;
}

/*
Author : Yash Darshankar
Date : 22/01/2025
Description: According to Latest UI Disussions
*/

.mainRenderList tr,
.renderTable tr {
  text-align: left;
  // border-bottom: 1px solid $border-bottom-color;
}

.renderTable th {
  word-wrap: break-word;
  max-width: 132px;
  padding-left: 0.5rem !important;
}

/* Alternating row colors */
.configureList tr:nth-child(even),
.configureList tr:nth-child(even) td,
.RenderListPagination tr:nth-child(even),
.RenderListPagination tr:nth-child(even) td,
.mainRenderList tr:nth-child(even),
.mainRenderList tr:nth-child(even) td {
  background-color: $alternate-tr;
}

.mainRenderList tr:nth-child(odd),
.RenderListPagination tr:nth-child(odd),
.configureList tr:nth-child(odd) {
  background-color: #ffffff;
}

.draftSurveyRenderTable tr {
  border-bottom: none;
}

.renderTableSetwise tr {
  border-bottom: none;
}

.actionBtns {
  display: flex;
  gap: 8px;
}

.radioOptions {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.listform {
  display: flex;
  align-items: center;
  width: auto;
}

.JICertificate .listform {
  justify-content: end;
}

.align-end {
  justify-content: end;
}


.csv-icon {
  margin-bottom: 0%;
}

#search-icon {
  padding: 0.1rem 0.5rem;
}

.form-control {
  appearance: auto !important;
}

.popup_body {
  max-height: "calc(100vh - 210px)";
  overflow-x: "scroll";
}

.modal-dialog-scrollable .modal-content {
  width: 95vw;
}

.modal-header {
  background: $danger;
  color: $white;
  padding: 8px;
  padding-left: 32px;
  padding-top: 16px;
  padding-right: 32px;
}

.modal-title {
  font-size: 1rem;
}

.modal-footer {
  padding: 8px;
  padding-right: 32px;
}

.renderTable> :not(caption)>*>* {
  padding: 0.5rem 0;
}

.customMargin-16 {
  margin-top: 16px;
}

.List-Btn {
  background-color: $danger;
  color: $white;
  border-radius: 50px;
  border: none;
}

.List-Btn:focus {
  background: $danger;
  color: $white;
  opacity: 0.7;
}

.List-Btn:hover {
  background: $danger;
  color: $white;
  opacity: 0.6;
}

.List-Btn:active {
  background: $danger;
  color: $white;
  opacity: 0.5;
}

.accordion-item {
  margin-top: 16px;
}

.dropdown_options {
  background-color: blue;
  padding: 0%;
  margin: 0%;
  width: fit-content;
}

.select-entity {
  display: flex;
  align-items: center;
}

.List-Btn-remove {
  border: none;
  background-color: $white;
}

.List-Btn-disabled {
  border: none;
  background-color: grey;
  color: $white;
  border-radius: 50px;
}

.button-container {
  display: flex;
  gap: 16px;
  margin-left: 4px;
}

.dropdown-toggle,
.dropdown-toggle:hover,
.dropdown-toggle:focus {
  background-color: white !important;
  font-family: $font-family-poppins;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: $form-label-color;
  width: 100%;
  overflow: hidden;
  height: $select-height;
  display: flex;
  justify-content: center;
  align-items: center;

}

.labelValue {
  font-family: $font-family-poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: grey;
}

.InputBtn-Active {
  background-color: "red";
}

.table-container {
  width: 100%;
  border-collapse: collapse;
  margin-top: $select-height;
}

.table-container th,
.table-container td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table-container th {
  background-color: #f2f2f2;
}

.iconBtn {
  padding: 8px;
  color: $text-color;
  border-radius: 4px;
  background-color: $white;
  border: 1px solid $danger;
  width: 90px;
  font-family: $font-family-poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: center;
  white-space: pre-wrap;
  min-height: 36px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}


.iconBtn:hover {
  background-color: $danger;
  color: $white;
}

.iconBtn1 {
  /* padding: 0px 16px; */
  // background-color: white;
  // border-radius: 32px;
  // color: $danger;
  // border: 2px solid #CC1E29;
  // height: 18px;
  // width: 18px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // margin-right: 11px;
  // font-weight: 700;
  /* padding: 0px 16px; */
  /* border-radius: 32px; */
  // color: $danger;
  /* border: 2px solid #CC1E29; */
  /* height: 18px; */
  /* width: 18px; */
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-right: 4px;
  // margin-right: 11px;

}

.iconBtn2 {
  // background-color: white;
  // border-radius: 32px;
  // color: $danger;
  // border: 2px solid #CC1E29;
  // height: 18px;
  // width: 18px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // font-weight: 700;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-right: 4px;
  // margin-left: 11px;

}

.actionColumn {
  position: relative;
}

.maxWidth {
  width: max-content;
}

.actionColumn>i {
  cursor: pointer;
}

.popupOptions {
  position: absolute;
  background-color: $white;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 4px;
  box-shadow: 4px 4px rgba(95, 95, 95, 0.1019607843);
  z-index: 1;
  width: max-content;
  max-width: 250px;
  left: -108px;
  top: 28px;
  // left: 8px;
  // top: 70px;
  border: 1px solid $danger;
  padding: 4px;
}

.popupOptions>div {
  padding: 4px;
  // padding: 4px 16px;
  font-family: $font-family-poppins;
  color: $text-color;
  text-wrap: wrap;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;

}

.popupOptions>div>button {
  color: $text-color;
}

.popupOptions>div>button:hover {
  color: $text-color-black;
}

// /* Change the color of the button when hovering over the div */
// .popupOptions>div:hover>button {
//   color: black;
// }

.popupOptions>div i {
  height: 18px;
  width: 18px;
  margin-right: 8px;
}

.popupOptions>div:hover {
  border-left: 2px solid red;
  color: $text-color-black;
}


// .popupOptions>div:hover,
// .popupOptions>div i:hover,
// .popupOptions>div>button:hover {
//   color: $danger !important;
// }


.actionColumn>i,
.actionColumn>button {
  margin: 4px;
}

.container {
  margin: 20px;
  max-width: 910px;
  overflow-y: scroll;
}

.container input {
  width: 70px;
}

.select-group,
.add-sample-btn {
  margin-left: 10px;
}

.data-table {
  border-collapse: collapse;
  width: 100%;
}

.data-table th,
.data-table td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.data-table th {
  background-color: #f2f2f2;
}

.result_column {
  text-align: center;
}

.accordion-button:not(.collapsed) {
  background-color: $danger;
  color: $white;
}

.accordion-collapse.collapse.show>.accordion-body>a.active.nav-link {
  color: $text-color;
  font-weight: bold;
  background-color: white !important;
  border-right: 6px solid $danger;
}

.accordion-collapse.collapse.show>.accordion-body>a.nav-link i {
  color: $danger;
  width: 26.25px;
  height: 27.19px;
  top: 2.81px;
  left: 1.88px;
  gap: 0px;
  margin-left: 10px;
}

.accordion-button:not(.collapsed)::after {
  filter: brightness(0) invert(1);
}

.adv_table_thead th {
  border: 1.5px solid $text-color-black;
}

.adv_table_thead td {
  padding: 0rem 0.5rem;
}

.adv_table_thead div {
  padding: 0 !important;
  margin: 0 !important;
}

.btn-number,
.btn-number:hover,
.btn-number:active,
.btn-number:focus {
  background-color: $danger;
  color: $white;
}

.input-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.plus-min-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header_icon {
  color: $text-color;
  display: block;
  cursor: pointer;
  font-size: 25px !important;
}

.header_icon_profile {
  color: $text-color;
  display: block;
  cursor: pointer;
  font-size: 40px !important;
  vertical-align: middle;
}


.profileImg {
  border-radius: 40px;

  width: 44px;
  height: 44px;

  background-color: $white;
}

.profileImgBg {
  background-color: white !important;
}

.header_label,
.header_label:hover,
.header_label:active,
.header_label:focus {
  height: 28px;

  padding: 9px 14px 9px 14px;

  border-radius: 0px 12px 0px 12px;

  background: $danger;

  font-family: $font-family-poppins;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 14.52px !important;
  text-align: left !important;

  color: #f4f4f4;
}

.tcrcLogo {
  // background-color: #f7f7f7;
  background-color: $white;
  position: fixed;
  width: 17vw;
  top: 0px;
  height: calc($navHeight);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.container-fluid {
  padding: 0% !important;
}

.card-title-icon {
  color: $text-color;
}

.nav-sidebar-title {
  font-family: $font-family-poppins;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: left;
}

.nav-sidebar-subtitle {
  font-family: $font-family-poppins;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.52px;
  text-align: left;
}

.trash_btn,
.trash_btn:hover,
.trash_btn:focus,
.trash_btn:active {
  border: none;
  background: none;
  color: $danger;
}

input[type="date"]::placeholder {
  font-family: $font-family-poppins;
  font-size: 12px;


  font-weight: 200;
  line-height: 14.52px;
  text-align: left;
}

select.default-placeholder option:first-child {
  font-family: $font-family-poppins;
  font-size: 12px;


  font-weight: 200;
  line-height: 14.52px;
  text-align: left;
}

.jrf_container {


  display: flex;
  flex-direction: row;
  width: fit-content;
  flex-wrap: wrap;
}

.jrf_container_btns {
  display: flex;
  justify-content: right;
  gap: $select-height;
}

.jrf_container_btns_main {
  display: flex;
  gap: 12px;
}

.jrf_container_btns>select {
  border-radius: 1px;
  color: $text-color;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23CC1E29" className="bi bi-caret-down-square-fill" viewBox="0 0 16 16"><path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm4 4a.5.5 0 0 0-.374.832l4 4.5a.5.5 0 0 0 .748 0l4-4.5A.5.5 0 0 0 12 6z"/></svg>');
  background-position: right center;
  background-repeat: no-repeat;
  padding-right: 16px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.jrf_input,
.jrf_select {
  width: 128px;
  height: 22px;
  border: 1px solid #e9e9e9;
  background-color: $white;
  font-family: $font-family-poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 1px;
}

.section_title {
  background-color: $backgroundColorScreen;
  width: calc(100% + 2rem);
  margin-left: -1rem;
  padding-top: 23px;
  // padding-bottom: 30px;

}

.section_title_support {
  padding-bottom: 30px;

}

.section_card {
  margin-top: 0px;
  width: 100%;
}

.section_card_body {
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #F8F9F9;
}


.main_form {
  // width: 80vw;
  // width: 100% !important;
  --bs-gutter-x: 1.85rem;
  --bs-gutter-y: 0;
  display: flex;
  background-color: white;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
  padding-left: 32px;
  border-radius: 16px;
  margin-bottom: 28px;
}

.submitBtn_container {
  display: flex;
  justify-content: right;
}

.errorMsg {
  padding-left: 10px;
  padding-top: 4px;
  font-family: $font-family-poppins;
  font-size: 10px;
  font-weight: 700;
  line-height: 12.52px;
  letter-spacing: 0em;
  text-align: left;
  color: $danger;
  display: flex;
  align-items: center;
}

.horizontalLine {
  background-color: #e9e9e9;
  height: 1px;
  margin: 15px 0px;
}

.section_heading {
  color: $danger !important;
  width: 100% !important;
  font-family: $font-family-exo !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 26.58px !important;
  text-align: left !important;
  margin-top: 16px;

}


.section_heading2 {
  font-family: $font-family-poppins;
  font-size: 14px !important;
  font-weight: 800 !important;
  line-height: 18px !important;
  text-align: left !important;
  color: $text-color !important;
  width: 100% !important;
  margin-bottom: 16px;
  margin-top: 8px;
}

.section_sub_heading {
  font-family: $font-family-poppins;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 16.94px !important;
  color: $text-color !important;
  width: 100%;
  margin-left: 37px;
}

.draft_table_subtitle {
  font-family: $font-family-poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: $text-color-black !important;
  margin-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px dashed $form-label-border;
}

.draft_bottom_table {
  // width: 87%;
  width: 60%;
  font-family: $font-family-poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: $text-color-black;
  margin: 24px 0px;
  margin-left: 41px;
}

.draft_bottom_table_second {
  width: 42%;
  font-family: $font-family-poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: $text-color-black;
  margin: 24px 0px;
  margin-left: 41px;
}

.draftSurveyRenderTable {
  width: 60%;
  margin-left: 41px;
}

.draftSurveyRenderTable_final {
  width: 100%;

}

.draftSurveyRenderTable thead tr {
  border: none !important;
}

.draftSurveyRenderTable td,
.draftSurveyRenderTable th {

  font-family: $font-family-poppins;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  text-align: left !important;
  border-style: hidden;

  .mx-2 {
    margin-left: 0px !important;
  }
}

.draftSurveyRenderTable .form-group {
  display: flex;
  justify-content: left !important;
}

.draftSurveyRenderTable_btns {
  justify-content: end;
  margin: 0px !important;

  .saveBtn {
    margin: 0px;
  }
}

.draftSurveyRenderTable_btns_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section_card_body_vesselList {
  padding-bottom: 0px;

  .section_title {
    padding-bottom: 0px;
    margin-left: -1rem;
  }
}

.draft_survey_remarks {
  width: 60%;
  margin-left: 41px;
}

.singleCommonFieldContainer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 90%;

}

.singleCommonFieldContainer_calc {
  width: 55%;
}

.singleCommonFieldContainer .form-group {
  width: 40% !important;
}

.singleCommonFieldContainer p {
  font-family: $font-family-poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 9px;
}



.singleCommonFieldContainer p span {
  border-radius: 4px;
  background-color: #DAEEFF;
  padding: 7px;
}

.singleCommonFieldContainer p:first-child span {
  background-color: #CCDDCC;
}

.CreateConfirugationBtn {
  height: $select-height;
  padding: 0px 8px;
  margin-top: 0px;
}


// RadioButton.css
.radioOption>input[type="radio"] {
  // appearance: none;
  // /* Removes default styling */
  // width: 14px;
  // height: 14px;
  // border-radius: 50%;
  // position: relative;
  // background-color: $white;
  // border: 2px solid $text-color;
  accent-color: $danger;
}

// .radioOption>input[type="radio"]::before {
//   content: '';
//   position: absolute;
//   width: 5.6px;
//   height: 5.6px;
//   top: 2px;
//   left: 2.5px;
//   background-color: $text-color;
//   border-radius: 50%;
// }

// .radioOption>input[type="radio"]:checked {
//   background-color: $white;
//   border: 2px solid $danger;
//   /* Color when checked */
// }

// .radioOption>input[type="radio"]:checked::before {
//   content: '';
//   position: absolute;
//   width: 5.6px;
//   height: 5.6px;
//   top: 2px;
//   left: 2.5px;
//   background-color: $danger;
//   border-radius: 50%;
// }

.optionDropdown {
  display: flex;
  gap: 8px;
  padding: 8px;
  border-bottom: 1px solid #D8D8D8;
}

.optionDropdown {
  border-bottom: 1px solid #D8D8D8;
}

.previous_btn {
  margin-right: 27px;
}

.next_btn {
  margin-left: 27px;
}



.JIPopupModalContainer .main_form {
  box-shadow: 0px 0px 4px 0px #D8D8D880;
  border-radius: 16px;
  margin-bottom: 24px;
  width: 100%;
}

.section_heading_middle {
  color: $danger !important;
  width: 100% !important;
  font-family: $font-family-exo !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 26.58px !important;
  text-align: center !important;
  margin-top: 16px;
}

.nav-tabs {
  border: none;
}

// /* Global custom scrollbar styling for horizontal scrollbars */
// ::-webkit-scrollbar:horizontal {
//   height: 10px;
//   background-color: #f0f0f0;
//   border-radius: 5px;
// }

// ::-webkit-scrollbar-thumb:horizontal {
//   background-color: $text-color;
//   border-radius: 4px;
// }

// ::-webkit-scrollbar-thumb:horizontal:hover {
//   background-color: #515151;
//   /* Slightly darker on hover */
// }

// ::-webkit-scrollbar-track:horizontal {
//   background-color: #eee;
// }
// /* Custom scrollbar styling */
.renderList_table_container::-webkit-scrollbar,
.tableContainer::-webkit-scrollbar {
  /* height for horizontal scrollbar */
  background-color: #f0f0f0;

  height: 10px;
  border-radius: 5px;
  cursor: pointer;
  /* scrollbar track color */
}

.renderList_table_container::-webkit-scrollbar-thumb,
.tableContainer::-webkit-scrollbar-thumb {
  background-color: $text-color;
  /* scrollbar thumb (slider) color */
  border-radius: 4px;
  cursor: pointer;

  /* rounded scrollbar thumb */
}

.renderList_table_container::-webkit-scrollbar-thumb:hover,
.tableContainer::-webkit-scrollbar-thumb:hover {
  background-color: #535252;
  cursor: pointer;

  /* darker red when hovered */
}

/* Optional: Change scrollbar track color */
.renderList_table_container::-webkit-scrollbar-track,
.tableContainer::-webkit-scrollbar-track {
  background-color: #eee;
  cursor: pointer;

  /* track background color */
}

.normalSelect>option {
  display: flex;
  gap: 8px;
  padding: 8px;
  border-bottom: 1px solid #D8D8D8;
}

.customInput_table {
  font-family: $font-family-poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: $text-color;
  width: 80%;
}

.if_avail_checklist {
  font-family: $font-family-poppins;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #B3B3B3;
}

.profile-menu {
  // width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
}

.profile-menu>.dropdown-toggle {
  height: auto;
}

.dotted_underline {
  border: none;
  /* Remove default styling */
  border-top: 1px dotted $form-label-border;
  /* Dotted top border */
  margin: 0;
  /* Adjust margin as needed */
}

.main-body {
  overflow-y: auto;
  height: 100vh;
  background-color: #F8F9F9;
}

/*
Author : Yash Darshankar
Date : 21/06/2024
Description: This is CSS for Scroll According to Figma.

*/
.main-body::-webkit-scrollbar {
  width: 10px !important;
  background-color: #f0f0f0 !important;
  border-radius: 5px !important;
  cursor: pointer !important;
}

.main-body::-webkit-scrollbar-thumb {
  background-color: $text-color !important;
  border-radius: 4px !important;
  cursor: pointer !important;
}

.main-body::-webkit-scrollbar-thumb:hover {
  background-color: #535252 !important;
  cursor: pointer !important;
}

.main-body::-webkit-scrollbar-track {
  background-color: #eee !important;
  cursor: pointer !important;
}

//18-11-2024 Changes :
.popupInwardModal::-webkit-scrollbar {
  width: 10px !important;
  background-color: #f0f0f0 !important;
  border-radius: 5px !important;
  cursor: pointer !important;
}

.popupInwardModal::-webkit-scrollbar-thumb {
  background-color: $text-color !important;
  border-radius: 4px !important;
  cursor: pointer !important;
}

.popupInwardModal::-webkit-scrollbar-thumb:hover {
  background-color: #535252 !important;
  cursor: pointer !important;
}

.popupInwardModal::-webkit-scrollbar-track {
  background-color: #eee !important;
  cursor: pointer !important;
}

///////////
.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 100% !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 100% !important;

}

.plus_icon_btn {
  background: none;
  outline: none;
  font-family: $font-family-poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  text-decoration-skip-ink: none;
  border: none;
  color: $text-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plus_icon_btn:hover {
  color: $text-color-black;
}

.plus_icon {
  width: 28px;
  height: 28px;
  background-color: $danger;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  border-radius: 20px;
  margin-bottom: 12px;
}

.ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content.ck-sticky-panel__content_sticky {
  border-bottom-width: 1px;
  z-index: -1;
}

.jrf_terms_and_conditions_UI .radioOption {
  align-items: flex-start !important;

}

.jrf_terms_and_conditions_UI .radioOption input[type="checkbox"] {
  margin-top: 4px;
}

.jrf_terms_and_conditions_UI .radioOption label {
  text-align: justify;
}

.draft_survey_td {
  padding-left: 0.75rem !important;
}

.Draft_survey_imp_td {
  color: $text-color-black !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 21.94px !important;
  text-align: left !important;
}

.svg-hover-effect {
  stroke: $text-color-black;
}

.svg-hover-effect:hover {
  stroke: #737373;
}

.svg-hover-effect-path {
  fill: $text-color-black;
}

.svg-hover-effect:hover .svg-hover-effect-path {
  fill: #737373;
}

// .card-body{
//   padding: 0px;
// }


.date_time .react-datepicker {
  display: flex;
}

.last-td .actionColumn {
  display: flex;
}

.ck-rounded-corners .ck.ck-editor__editable:not(.ck-editor__nested-editable),
.ck.ck-editor__editable.ck-rounded-corners:not(.ck-editor__nested-editable) {
  border-radius: var(--ck-border-radius);
  border-radius: 0px 0px 8px 8px !important;
}

.ck.ck-reset_all,
.ck-reset_all *:not(.ck-reset_all-excluded *) {
  /* border-collapse: collapse; */
  font: normal normal normal var(--ck-font-size-base) / var(--ck-line-height-base) var(--ck-font-face);
  color: var(--ck-color-text);
  text-align: left;
  white-space: nowrap;
  cursor: auto;
  float: none;
  border-radius: 8px 8px 0px 0px !important;
}

.Action-header {
  text-align: left !important;
}

.individual-card {
  margin-bottom: calc(40px - 1rem) !important;
}

.page_numOfnum {
  font-family: $font-family-poppins;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 12px 15px;
  color: #616161;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
}

.small-gap-chklst {
  height: $select-height;
}

.DateTimePicker .react-datepicker {
  display: flex;
}

.rs-picker-error>.rs-picker-input-group,
.rs-picker-error>.rs-picker-input-group:hover,
.rs-picker-error>.rs-picker-input-group,
.rs-picker-error>.rs-picker-input-group:focus,
.rs-picker-error>.rs-picker-input-group,
.rs-picker-error>.rs-picker-input-group {
  border-color: white !important;
  outline: none;
  border: none;
}

.rs-picker-input-group,
.rs-picker-input-group:focus-within {
  border: 1px solid #dee2e6 !important;
  outline: none !important;

}

.loadMoreOptionsSelect {
  margin-top: 0px !important;
  padding: 0px 8px !important;
}

.countDisplay {
  width: 16px;
  height: 16px;
  border-radius: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: $danger;
  color: $white;
  font-family: $font-family-poppins;
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
}

.renderList_sub_table_heading .form-group {
  max-width: 148px;
}

// Srushti 

.renderList_sub_table_heading .groupforlabelleddropdown {
  max-width: 75% !important;
  width: 60% !important;
  display: flex;
  align-items: center;
  font-size: small;
}

.expenseListLabel {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.groupforlabelleddropdown label {
  width: 50% !important;
  white-space: nowrap;
  font-weight: 600;
  font-family: Poppins, sans-serif;

}

// --------------------------------
.addNewSetData.card {
  background-color: #ffffff;
  margin-left: 12px;
  margin-top: -6px !important;
}

.testPreviewContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-disabled {
  background: #8D170A;
  color: $white;
}

.LoadingText {
  font-family: $font-family-poppins;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0%;
  color: $text-color-black;
  margin-top: $select-height;
}

.LoadingTextContainer {
  width: 100vw;
  display: flex;
  justify-content: center;
}

// .renderTable .form-group .mx-2 {
//   margin-left: 0px !important;
//   margin-right: 1px !important;
// }
.srNoPad {
  padding-left: 0.6rem !important;
}

.certificateBtns {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin: 2px 10px 5px;
}

.activityIcon {
  max-width: unset !important;
  width: 40px;
}

.inputIcon textarea {
  height: unset !important;
  height: 36px !important;
  border: 1px solid #d8d8d8;
  font-family: Poppins, sans-serif;
  line-height: 14.52px !important;
  text-align: left;
  color: #0d1016;
  padding-left: 11px;
  outline: none;
}

.tooltip-text {
  position: absolute;
  // bottom: -150%;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 8px;
  border-radius: 5px;
  font-size: 14px;
  width: 200px;
  white-space: normal;
  word-wrap: break-word;
  text-align: center;
  transition: opacity 0.3s ease-in-out;
  z-index: 9999;
}

.tooltip-container:hover .tooltip-text {
  opacity: 1;
  visibility: visible;
}

/* Prevent tooltip from going outside the screen */
.tooltip-container {
  position: relative;
  display: inline-block;
  max-width: 100%;
}

.tooltip-text::after {
  content: "";
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent black transparent;
  /* Creates the tooltip arrow */
}

.InvoiceModalInward {
  padding: 0.5rem 0.5rem !important;
}